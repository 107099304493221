import LogoutIcon from "@mui/icons-material/Logout";
import React, { useEffect, useState } from "react";


import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector,shallowEqual } from "react-redux";
import { authActions } from "../redux-store";
import Typography from "@mui/material/Typography";
import TelegramIcon from "@mui/icons-material/Telegram";
import NotesIcon from "@mui/icons-material/Notes";
import FolderIcon from "@mui/icons-material/Folder";
import backend from "../api";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import sessionstorage from "sessionstorage";
import { setChatHistoryId } from "../redux-store/auth/actions";
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import SellIcon from '@mui/icons-material/Sell';
import PeopleIcon from '@mui/icons-material/People';

import './Sidebar.css'

const drawerWidth = 250;


// The rest of your components remain unchanged


function ResponsiveDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navBtn = sessionstorage.getItem("navBtn")

  const [selectedButton, setSelectedButton] = React.useState(navBtn?navBtn:"Insights");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = React.useState(false);
  const BotID = localStorage.getItem('projectID');
  const ProjectName = localStorage.getItem('projectName');
  const ProjectID = localStorage.getItem("projectID")
  const [userData, setUserData] = useState();

  const userId = user._id;

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoading(true);
        const response = await backend.put(`/api/user/${userId}`);
        setUserData(response.data.data)
      } catch (error) {
        console.error("Failed to fetch sellers:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);


 

  const navigateTo = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };




 const saveChat = async () => {
  const userId = user._id
  
    try {
    const body = {
      UserId: userId,
      projectId:ProjectID
    };
    
    const response = await backend.post("/chats", body);
        // setLoading(false);
      console.log(response)
      const RouteID = response?.data?.data?._id
      // localStorage.setItem("chatId", RouteID)
      dispatch(setChatHistoryId(RouteID))
      await UpdateProjectById(RouteID)
      // navigateTo(`/chat/${ProjectID}/${RouteID}`)

  } catch (error) {
    console.error(error);
    // toast.error("Error saving story");
  }

};

const UpdateProjectById = async (newChatData) => {

  console.log(newChatData)

  const id = await user?._id;
  try {
    // Fetch the existing project data
    setLoading(true);
    const response = await backend.get(`/mainfolder/${ProjectID}`);
    console.log(response?.data)
    const existingProjectData = response?.data;

    // Modify the chats array by merging new data with the existing data
    const updatedChats = [...existingProjectData.chats, newChatData];

    // Prepare the updated project body
    const body = {
      ...existingProjectData,
      chats: updatedChats,
    };
    // console.log(typeof(ProjectID))
    // Make the PUT request to update the project
    const resp = await backend.put(`/mainfolder/${ProjectID}`, body);

    console.log(resp)
     setLoading(false);
    // Optionally, you can return the updated project data
    navigateTo(`/chat/${ProjectID}/${newChatData}`)
  } catch (error) {
    // Handle error
    console.error("Error updating project:", error.message);
    throw error;
  }
};



    useEffect(() => {
      if(selectedButton === 'AI Assistant') {
        sessionstorage.setItem("navBtn", selectedButton)
        navigateTo(`/assistant`)
      }
   
      if(selectedButton === 'Chat') {
        // navigateTo('/chat')
        sessionstorage.setItem("navBtn", selectedButton)
        saveChat();
      }

      if(selectedButton === 'Chats') {
        sessionstorage.setItem("navBtn", selectedButton)
        dispatch(setChatHistoryId(''))
        navigateTo(`/inbox/`)
      }
      if(selectedButton === 'Sellers') {
        sessionstorage.setItem("navBtn", selectedButton)
        navigateTo(`/sellers`)
      }
      if(selectedButton === 'Buyers') {
        sessionstorage.setItem("navBtn", selectedButton)
        navigateTo(`/dashboard`)
      }
      if(selectedButton === 'Buyers') {
        sessionstorage.setItem("navBtn", selectedButton)
        navigateTo(`/dashboard`)
      }
    
    
    
    },[selectedButton])

    const handleLogout = async () => {
      localStorage.clear();
      navigateTo("/");
      await dispatch(authActions.logout());
      // alert("Signed out successfully")
    };

    
    const handleHomeClick = async () => {
      // sessionstorage.setItem("navBtn", 'Sellers')
      // sessionstorage.setItem("refresh", 'yes')
      // navigateTo("/");
      sessionstorage.setItem("navBtn", '')
      sessionstorage.setItem("refresh", 'yes')
      navigateTo("/account");
      
     
      // alert("Signed out successfully")
    };




  const drawer = (
    <div style={{
      background: "#FBFBFD",
      // color: "rgba(255, 255, 255, 0.6)",
      height: "100vh",
      // width: "360px", // Set a fixed width for the sidebar
      
    }}
    className="element"
    >
      <Toolbar
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "transparent",
        }}
      >
        <a onClick={()=>handleHomeClick()} style={{ marginTop: "2rem", cursor:"pointer", marginBottom: "2rem" }}>
          <img src={userData?.img?userData?.img:"/logo-orange.png"} alt="logo" style={{ width:"65px",height: "65px", borderRadius:"50px" }} />
        </a>
      </Toolbar>
    
    
    
      <div >
        {
          user.role=="buyer"? 
          <List>
          {[
            "Sellers",
            "Chats",
            "AI Assistant"
      
          ].map((text, index) => (
            <ListItem key={text} style={{width:"100%",background:
            selectedButton === text
              ? "white"
              : "transparent", 
              borderRight: selectedButton === text ? "6px solid linear-gradient(45deg, #007bff, #6610f2)" : "",
              // height:"50px",
              padding:"0.5rem"
              
          }}>
              <ListItemButton
                onClick={() => setSelectedButton(text)}
                style={{
                  background: "transparent",
                  color: "black",
                  width: "100%",
                  display:"flex",
                  alignItems: "center",
                  padding:"0rem 1rem"
                }}
                disabled={index===10?true:false}
                disableRipple // Disables the ripple effect
                disableTouchRipple // Disables the ripple effect on touch devices
              >
                <ListItemIcon style={{padding:"0rem 0rem"  }}>
                  {index === 0 ? (
                     <PeopleIcon 
                     style={{
                       width: "17px",
                       height: "17px",
                       color: selectedButton === text?"linear-gradient(45deg, #007bff, #6610f2)":""

                     }}
                   />
                  ):index === 1 ? (
                    <SellIcon
                      style={{
                        width: "19px",
                        height: "17px",
                        color: selectedButton === text?"linear-gradient(45deg, #007bff, #6610f2)":""

                      }}
                    />
                  ) : index === 2 ? (
                    <ElectricBoltIcon
                      style={{
                        width: "19px",
                        height: "19px",
                        color: selectedButton === text?"linear-gradient(45deg, #007bff, #6610f2)":""

                      }}
                    />
                  ) : index === 3 ? (
                    <img src={selectedButton === text?"/icons/coach.png":"/icons/coach-dark.png"} alt="coach"
                      style={{
                        width: "17px",
                        height: "18px",
                      }}
                    />
                  ) : index === 4 ? (
                    <img src="/icons/presentation.png" alt="presentation"
                      style={{
                        width: "17px",
                        height: "17px",
                      }}
                    />
                  ) : index === 5 ? (
                    <img src="/CustomQA.png" alt="Custom Q&A"
                      style={{
                        width: "17px",
                        height: "17px",
                      }}
                    />
                  ) : index === 5 ? (
                    <img src="/inbox.png" alt="Inbox Icon"
                    style={{
                      width: "17px",
                      height: "17px",
                    }}
                  />
                  ) : (
                    <FolderSpecialIcon />
                  )}
                </ListItemIcon>
             
                <Typography
                  style={{
                    fontWeight: selectedButton === text ? "600" : "400",
                    fontSize: "15px",
                    fontFamily: "Poppins, sans-serif",
                    color: selectedButton === text ?"linear-gradient(45deg, #007bff, #6610f2)":'black',
                    marginLeft:"-1.5rem"
                  }}
                >
                  {text} 
                 
                </Typography>
              </ListItemButton>
           
            </ListItem>
          ))}
  
        </List>:
        <List>
        {[
        
        "Buyers",
        "Chats",
        "AI Assistant",
        
    
        ].map((text, index) => (
          <ListItem key={text} style={{width:"100%",background:
          selectedButton === text
            ? "white"
            : "transparent", 
            borderRight: selectedButton === text ? "6px solid linear-gradient(45deg, #007bff, #6610f2)" : "",
            // height:"50px",
            padding:"0.5rem"
            
        }}>
            <ListItemButton
              onClick={() => setSelectedButton(text)}
              style={{
                background: "transparent",
                color: "black",
                width: "100%",
                display:"flex",
                alignItems: "center",
                padding:"0rem 1rem"
              }}
              disabled={index===10?true:false}
              disableRipple // Disables the ripple effect
              disableTouchRipple // Disables the ripple effect on touch devices
            >
              <ListItemIcon style={{padding:"0rem 0rem"  }}>
                {index === 0 ? (
                   <PeopleIcon 
                   style={{
                     width: "17px",
                     height: "17px",
                     color: selectedButton === text?"linear-gradient(45deg, #007bff, #6610f2)":""

                   }}
                 />
                ):index === 1 ? (
                  <SellIcon
                    style={{
                      width: "19px",
                      height: "17px",
                      color: selectedButton === text?"linear-gradient(45deg, #007bff, #6610f2)":""

                    }}
                  />
                ) : index === 2 ? (
                  <ElectricBoltIcon
                    style={{
                      width: "19px",
                      height: "19px",
                      color: selectedButton === text?"linear-gradient(45deg, #007bff, #6610f2)":""

                    }}
                  />
                ) : index === 3 ? (
                  <img src={selectedButton === text?"/icons/coach.png":"/icons/coach-dark.png"} alt="coach"
                    style={{
                      width: "17px",
                      height: "18px",
                    }}
                  />
                ) : index === 4 ? (
                  <img src="/icons/presentation.png" alt="presentation"
                    style={{
                      width: "17px",
                      height: "17px",
                    }}
                  />
                ) : index === 5 ? (
                  <img src="/CustomQA.png" alt="Custom Q&A"
                    style={{
                      width: "17px",
                      height: "17px",
                    }}
                  />
                ) : index === 5 ? (
                  <img src="/inbox.png" alt="Inbox Icon"
                  style={{
                    width: "17px",
                    height: "17px",
                  }}
                />
                ) : (
                  <FolderSpecialIcon />
                )}
              </ListItemIcon>
           
              <Typography
                style={{
                  fontWeight: selectedButton === text ? "600" : "400",
                  fontSize: "15px",
                  fontFamily: "Poppins, sans-serif",
                  color: selectedButton === text ?"linear-gradient(45deg, #007bff, #6610f2)":'black',
                  marginLeft:"-1.5rem"
                }}
              >
                {text} 
               
              </Typography>
            </ListItemButton>
         
          </ListItem>
        ))}

      </List>
        }
       
      
        <br />
       
      </div>

  
     <div
      style={{cursor:"pointer", paddingBottom:"1rem", paddingLeft:"1rem", position:"absolute", bottom:0}}>
      <Divider style={{width:"227px", marginBottom:"1rem"}}/>
     
      
       <div style={{display:"flex", alignItems:"center", marginBottom:"1rem"}}  onClick={()=>handleLogout()}>
      <img src="/icons/logout.png" style={{color:"black",width:"17px", height:"18px", marginLeft:"0.7rem"}}/>
      <a style={{fontSize:"16px", paddingLeft:"0.5rem", color:"black",  fontWeight:"600"}}>Logout</a>
      </div>
      </div> 
   
 

      
      
    </div>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (



    <Box sx={{ display: "flex"}} className="element">
      {/* <CssBaseline /> */}
      
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        style={{ background: "transparent", color: "black", height: "0px" }}
      >
       
        <Divider />
        <Toolbar sx={{ mr: 2, display: { sm: "none" } }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
        style={{
          boxShadow: "5px 0px 10px rgba(0, 0, 0, 0.07)", // Adding box shadow to the right
          borderRight: '0px solid transparent',
          border: 'none',
          height:"100vh"
        }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: '0px solid transparent',
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: '0px solid transparent',
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      {/* <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar  />
        <Dashboard />
      </Box> */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          pt:selectedButton==='AI Assistant'?0:5 ,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {/* <Toolbar />
      <Dashboard />
      <MenuFooter /> */}
        {/* {
        props.component
      } */}

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "100vh",
              alignItems: "center",
            }}
          >
            {/* <CircularProgress /> */}
            <img src="/loading-2.gif" alt="gif" />
          </div>
        ) : (
          props.component
        )}
      </Box>
      {/* Include your MenuFooter component here */}
    

    </Box>
    
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
