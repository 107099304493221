import React, { useState, useEffect } from "react";
import "./Menu.css";
import Nav from "./Nav";
import CloseIcon from '@mui/icons-material/Close';
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../redux-store";
import AuthPopup from "./auth-pop/Popup";

const Menu = () => {
  const { user } = useSelector((state) => state.auth);
  const ProjectID = localStorage.getItem("projectID")

  const [isOpen, setIsOpen] = useState(false);

  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();


  const handleOpenAuth= () => {setOpen(true); setIsOpen(false)};
  const handleCloseAuth = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);

  };

  
  const navigateToDashboard =() => {
    navigateTo(`/dashboard/`)
  }

  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  const navigateTo =useNavigate()

  const handleLogout = async () => {
    localStorage.clear();
    navigateTo("/");
    await dispatch(authActions.logout());
    toast.success("Signed out successfully")
  };
  
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return width>938?
  <Nav />:
  (
    <div className="menu-wrapper">
      <div
        className={`menu-icon ${isOpen ? "open" : ""}`}
        onClick={handleToggle}
      >
        <div className="menu-line"></div>
        <div className="menu-line"></div>
        <div className="menu-line"></div>
      </div>
      <nav className={`menu-navigation ${isOpen ? "open" : ""}`}>
        {/* <button className="close-btn" onClick={handleClose}>
          Close
        </button> */}
        <CloseIcon className="close-btn" onClick={handleClose}/>
        <ul>
          <li>
            <a
        href="/"
        style={{ color: "white", textDecoration: "none" }}
            >
              Home
            </a>
          </li>
          
          <li>
            <a
              href="/About"
              style={{ color: "white", textDecoration: "none" }}
            >
              Features
            </a>
          </li>
          <li>
            <a
             
              style={{ color: "white", textDecoration: "none" }}
            >
              Pricing
            </a>
          </li>
          <li>
            <a
             
              style={{ color: "white", textDecoration: "none" }}
            >
              FAQ
            </a>
          </li>
         

          <li>
            {
              user? <a
              onClick={()=>navigateToDashboard()}
              style={{ color: "white", textDecoration: "none" }}
            >
              Dashboard
            </a>: 
            <a
              href="/auth"
              style={{ color: "white", textDecoration: "none" }}
            >
              Sign In
            </a>
            }
           
          </li>

        
        </ul>
      </nav>

      <div>
    {open && <AuthPopup onClose={handleCloseAuth}/>}
    </div>
    </div>
  );
};

export default Menu;
