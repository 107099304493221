import React, {useEffect,useRef} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Button} from '@mui/material';

// import './FAQs.css'; // This is where you'll write your CSS

const FAQs = () => {
  const sectionRef = useRef(null); // Reference to the section

  const faqData = [
    {
      question: 'Lorem Ipsum is simply dummy text of the printing',
      answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
    },
    {
      question: 'Lorem Ipsum is simply dummy text of the printing',
      answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'
    },
    {
      question: 'Lorem Ipsum is simply dummy text of the printing',
      answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'
    },
    {
      question: 'Lorem Ipsum is simply dummy text of the printing',
       answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'
          },
    {
      question: 'Lorem Ipsum is simply dummy text of the printing',
      answer:'Lorem Ipsum is simply dummy text of the printing'
    }
    // ... Add the rest of your FAQ data here
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Add the animation class when the section is in the viewport
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-on-scroll-c');
        }
      },
      {
        root: null, // relative to the viewport
        rootMargin: '0px',
        threshold: 0.1, // trigger the callback when 10% of the element is visible
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section id='faq' ref={sectionRef}>
    <div className="faq-container" style={{ marginTop: "6rem", display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
    <Button variant="contained" sx={{
      backgroundColor: 'linear-gradient(45deg, #007bff, #6610f2)', // Set the button color
      color: 'white', // Set the text color
      '&:hover': {
        backgroundColor: 'linear-gradient(45deg, #007bff, #6610f2)', // Set the hover color
        color: "white",
        boxShadow:'none'
      },
      borderRadius: "25px",
      border: "1px solid linear-gradient(45deg, #007bff, #6610f2)",
      paddingLeft: "4rem",
      paddingRight: "4rem",
      fontWeight: "550",
      boxShadow:'none',
      marginTop:"5rem"
    }}>
      FAQS
    </Button>
    <h1 style={{ fontSize: "45px", fontFamily: "sans-serif, manrope", color: "#101828" }}>Frequently Asked Questions</h1>
    <div style={{ maxWidth: "800px", width: "100%", display: "flex", flexDirection: "column", alignItems: "left" }}>
      {faqData.map((faq, index) => (
        <Accordion key={index} style={{border:"1px solid linear-gradient(45deg, #007bff, #6610f2)", borderRadius:"10px", boxShadow:'none', margin:"0.5rem", padding:"1.3rem"}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
          >
            <Typography style={{fontWeight:"600"}}>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails style={{textAlign:"left"}}>
            <Typography>
              {faq.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  </div>
  </section>

  );
};

export default FAQs;
