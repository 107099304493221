import React from "react";
import { useParams } from "react-router-dom";
import "./App.css";
import Menu from "./Menu";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../redux-store";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AuthPopup from "./auth-pop/Popup";

import PersonIcon from '@mui/icons-material/Person';


function Nav() {
  const { user } = useSelector((state) => state.auth);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const handleOpenAuth = () => setOpen(true);
  const ProjectID = localStorage.getItem("projectID")

  const handleCloseAuth = () => {
    setOpen(false);
  };

  const navigateTo =useNavigate();

  const handleLogout = async () => {
    localStorage.clear();
    navigateTo("/");
    await dispatch(authActions.logout());
    toast.success("Signed out successfully");
  };

  const navigateToDashboard =() => {
    navigateTo(`/dashboard/`)
  }




  return (
    <div >
       
  <a style={{ position: "absolute", top: 0, left:160, cursor:"pointer", textDecoration:"none" }} href="/">
  <img src='logo-orange.png' alt="Logo" style={{marginTop:"1.7rem", width:"65px",height: "65px"}}/>
</a>
    <nav style={{ textAlign: "center", display:"flex", flexDirection:"row", justifyContent:"center", marginBottom: "25px", marginTop:"2.8rem" }}>
      
      {/* <a
        href="/#home"
       
        style={{
          color: "black",fontFamily:"manrope,sans-serif",fontSize:"17px",
          textDecoration: "none",
          marginRight: "2.1rem",
        }}
      >
        Home
      </a> */}
      <a
        href="/#how-it-works"
       
        style={{paddingRight:"1.5rem", color: "black",fontFamily:"manrope,sans-serif",fontSize:"17px", textDecoration: "none" }}
      >
        How it Works
      </a>
      <a
        href="/#features"
       
        style={{paddingRight:"1.5rem", color: "black",fontFamily:"manrope,sans-serif",fontSize:"17px", textDecoration: "none" }}
      >
        Features
      </a>
      <a
        href="/#pricing"
       
        style={{ color: "black", paddingRight:"1.5rem",fontFamily:"manrope,sans-serif",fontSize:"17px", textDecoration: "none" }}
      >
        Pricing
      </a>

      <a
        href="/#faq"
       
        style={{ color: "black",fontFamily:"manrope,sans-serif",fontSize:"17px", textDecoration: "none" }}

      >
        FAQ
      </a>

      {user?
      <a
      href="/dashboard"
     
      style={{
        color: "black",fontFamily:"manrope,sans-serif",fontSize:"17px",
        textDecoration: "none",
        
      }}
    >
      Dashboard
    </a>:null}


      {user ? (

 <span style={{ position: "absolute", top: 35, right:150, cursor:"pointer", textDecoration:"none" }} href="/login">
        <a onClick={()=>navigateToDashboard()}
        style={{ marginTop:'1rem',   }}
        >
    <PersonIcon style={{ color: "linear-gradient(45deg, #007bff, #6610f2)", fontSize: "35px" }} />
        </a>
                </span>

      ) : (
        <div style={{ position: "absolute", top: 35, right:135, cursor:"pointer", textDecoration:"none" }}>
          <span  href="/buyerauth">
        
        <a href="/buyerauth">
        <button
        style={{ color: "linear-gradient(45deg, #007bff, #6610f2)", height:"38px", background:"white", borderRadius:"8px",fontWeight:"600", border:"1px solid linear-gradient(45deg, #007bff, #6610f2)",fontFamily:"manrope,sans-serif",fontSize:"13px", padding:"0.3rem 2rem", textDecoration: "none", cursor:"pointer" }}

        >
          Login to Buyer
        </button>
        
        </a>
        </span>

        <span  href="/sellerauth">
        
        <a href="/sellerauth">
        <button
        style={{ color: "white", marginLeft:"1rem", height:"38px", background:"linear-gradient(45deg, #007bff, #6610f2)", borderRadius:"8px",fontWeight:"600", borderColor:"transparent",fontFamily:"manrope,sans-serif",fontSize:"13px", padding:"0.3rem 2rem", textDecoration: "none", cursor:"pointer" }}

        >
          Login to Seller
        </button>
        
        </a>
        </span>

        </div>
        
        
        
      )}
     
    </nav>

    <div>
    {open && <AuthPopup onClose={handleCloseAuth}/>}
    </div>
    </div>
  );
}

export default Nav;
