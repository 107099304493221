import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Configuration, OpenAIApi } from "openai";
import { Helmet } from "react-helmet";
import CircleIcon from '@mui/icons-material/Circle';
import backend from "../../api";
import { Grid } from "@mui/material";

const ChatContainer = styled.div`
  width: 600px;
  height: 500px;
  border-radius: 8px;
  background-color: #f8f8f8;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  
`;

const ChatHeader = styled.div`
  padding: 10px;
  background-color: #dcdcdc;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 15px;
  font-weight: 600;
  color: #333333;
  font-family: "Montserrat", sans-serif;
`;

const ChatContent = styled.div`
  height: calc(100% - 170px);
  padding: 16px;
  overflow-y: auto;
`;

const ChatMessage = styled.div`
  display: flex;
  justify-content: ${(props) => (props.isUser ? "flex-end" : "flex-start")};
  margin-bottom: 8px;
`;

const ChatBubble = styled.div`
  max-width: 70%;
  padding: 6px;
  border-radius: 8px;
  font-size: 15px;
  background-color: ${(props) => (props.isUser ? "#dcdcdc" : "#f1f1f1")};
  color: ${(props) => (props.isUser ? "#333333" : "#333333")};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
`;

const ChatInputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: #dcdcdc;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const ChatInput = styled.input`
  flex: 1;
  height: 25px;
  padding: 8px;
  border: none;
  border-radius: 4px;
  background-color: #ffffff;

`;

const ChatButton = styled.button`
  margin-left: 16px;
  padding: 8px 16px;
  background-color: #333333;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

function Assistant() {
  const [messages, setMessages] = useState([]);
  const [messagesHistory, setMessageHistory] = useState([]);

  const [inputText, setInputText] = useState("");
  const [displayText, setDisplayText] = useState("");
  const [loading, setLoading] = useState(false);
  const [sellers, setSellers] = useState([]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSendMessage();
    }
  };


  useEffect(() => {
    const fetchSellers = async () => {
      try {
        setLoading(true);
        const response = await backend.get('/api/user/all');
        const filteredSellers = response?.data?.data?.filter(s => s?.role === 'seller');
        setSellers(filteredSellers);
      } catch (error) {
        console.error("Failed to fetch sellers:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSellers();
  }, []);

 

  const styles = {
    fontFamily: 'Arial, sans-serif',
    fontSize: '16px',
    lineHeight: '1.5',
    color: 'black',
    
    whiteSpace: 'pre-wrap', // allow wrapping of long lines
    wordWrap: 'break-word' // break long words
  };

  const text =
    "  Greetings! I am developed by Hamza. Nice to meet you in the digital world. I'm here to give you a virtual hand and address any questions or concerns you may have. My knowledge and skills are at your disposal. So don't hesitate to ask me something. How can I be of service to you today?    ";


    const [isLightOn, setIsLightOn] = useState(true);

    useEffect(() => {
      const interval = setInterval(() => {
        setIsLightOn((prevIsLightOn) => !prevIsLightOn);
      }, 1000);
  
      return () => clearInterval(interval);
    }, []);

  const color = isLightOn ? 'green' : 'darkgrey';
  const style = {
    color,
    width: '15px',
    height: '15px',
  };

  useEffect(() => {
    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      setDisplayText((prevText) => prevText + text[currentIndex]);
      currentIndex++;
      if (currentIndex === text.length) {
        clearInterval(typingInterval);
      }
    }, 20);

    return () => {
      clearInterval(typingInterval);
    };
  }, []);

  const chatContentRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
  };

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleSendMessage = () => {
  const newText = `Query is ${inputText} and if my query is about sellers then Please answer based on this data if I asks about this type of any info whether it is services or description, seller id, seller profile link like "https://MayaIQ.vercel.app/sellers/sellerId"  or anything related to specific seller profile  and this data about all registered sellers is: ${JSON.stringify(sellers)} and the previous chat with you is or will be in this array if it is empty ${JSON.stringify(messages)} and give full details  if query is not about sellers or any services of given data or previous chat then talk normally`;
    
    if (newText.trim() !== "") {

      const newMessage = {
        content: newText.trim(),
        isUser: true,
      };

      const newMessageToDisplay = {
        content: inputText.trim(),
        isUser: true,
      };

      setMessageHistory((prevMessages) => [...prevMessages, newMessage])
      setMessages((prevMessages) => [...prevMessages, newMessageToDisplay]);
      setInputText("");

      sendBotMessage(newMessage.content);
    }
  };

  const sendBotMessage = async (userMessage) => {
    setLoading(true)
    const configuration = new Configuration({
      apiKey: "",
    });
    const openai = new OpenAIApi(configuration);

    try {
      const result = await openai.createChatCompletion({
        model: "gpt-4",
        temperature: 0.75,
        messages: [{ role: "user", content: userMessage }],
      });

      const botMessage = {
        content: result?.data?.choices[0]?.message?.content,
        isUser: false,
      };

      setMessageHistory((prevMessages) => [...prevMessages, botMessage])
      setMessages((prevMessages) => [...prevMessages, botMessage]);
      setLoading(false)
    } catch (error) {
      console.log(error);
    }
  };


  const extractS3Link = (message) => {
    const s3LinkPattern = /https:\/\/s3-hamza-new\.s3\.ap-south-1\.amazonaws\.com\/[^\s]+/g;
    const matches = message.match(s3LinkPattern);
    return matches ? matches[0] : null;
  };

//   const renderBotMessage = (content) => {
//     const s3Link = extractS3Link(content);
//     if (s3Link) {
//       // If the message contains an S3 link, render it as an image
//       return <img src={s3Link} alt="S3 Content" style={{width: "100px", height: "100px"}} />;
//     } else {
//       // Otherwise, render the content as plain text
//       return null;
//     }
//   };

const renderBotMessage = (content) => {
    // Splitting content by lines to individually process each part
    const lines = content.split(' - ');
    const s3Link = extractS3Link(content);
    console.log(s3Link);
    return (
      <>
        {lines.map((line, index) => {
          // Handling profile link extraction
          if (line.includes('Seller Profile Link:')) {
            const profileUrlMatch = line.match(/\(http:\/\/localhost:3000\/sellers\/\w+\)/g);
            if (profileUrlMatch) {
              const profileUrl = profileUrlMatch[0].slice(1, -1); // Removing parentheses
              return <div key={index}>
                {/* {s3Link? <img src={s3Link.trim()} alt="Image " style={{width: "100px", height: "100px"}} />:null} */}
                <br/><a href={profileUrl} target="_blank" rel="noopener noreferrer">Seller Profile Link</a>

                </div>;
            }
          }

        })}
      </>
    );
  };
  
  

  return (
    <>
    
      <div
        style={{
          marginTop: "1rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
 <h1 style={{ fontSize: "32px",  fontWeight: 500 ,  display:"flex", justifyContent:"center", fontFamily: "Montserrat sans-serif", color: "#333333" }}>
   <img style={{height:"70px", width:"70px"}} src="/logo-orange.png"/>   
 AI <span style={{marginLeft:"0.5rem", color: "#2077ff", fontWeight: 600 }}>ASSISTANT</span>
</h1>
<label
  style={{
    fontSize: "16px",
    textAlign: "center",
    color: "#999999",
    fontWeight: 600,
    marginLeft:"4.5rem",
    marginTop: "-35px",
    fontFamily: "Montserrat sans-serif",
    display:"flex", justifyContent:"center",
  }}
>
  Powered by MayaIQ
</label>
      </div>

      <Grid container spacing={2} style={{padding:"2rem", display:"flex", justifyContent:"center"}}>
     
        <Grid item xs={12} md={5} sm={12}>
        <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop:"1.5rem",
          paddingLeft: "1rem",
          paddingRight: "1rem",
        }}
      >
        <ChatContainer>
          <ChatHeader>Ask anything <span style={{display:"flex", justifyContent:"flex-end", marginTop:"-1rem"}}><CircleIcon style={{color:"green", width:"15px", height:"15px"}}/>Live </span></ChatHeader>

          <ChatContent ref={chatContentRef}>
            {messages.length === 0 ? (
              <div
                style={{
                  borderRadius: "8px",
                  textAlign: "justify",
                  maxWidth: "600px",
                  backgroundColor: "#dcdcdc",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                }}
              >
                <p
                  style={{
                    color: "black",
                    borderRadius: "5px",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                  }}
                >
                  {/* {displayText} */}
                  Ready to answer
                </p>
              </div>
            ) : null}
            {messages.map((message, index) => (
              <ChatMessage key={index} isUser={message.isUser}>
                <ChatBubble isUser={message.isUser}>
                <pre style={{
               ...styles,
               flex: "1", // Allow the content area to expand
               minWidth: "0", // Prevent the content from causing element collapse
             }}>
               {message.content}
             </pre>
                  {renderBotMessage(message.content)}<br/>
                  {/* {message.content.includes("home.jpg")?
                  <img style={{width:"100px", height:"100px"}} src="./home.jpg" />
                  :null} */}
                </ChatBubble>
              </ChatMessage>
            ))}
       {/* {loading?<p style={{display:"flex", justifyContent:"center", height:"150px", width:"150px", justifyItems:"left"}}>Typing...</p>:null} */}

          </ChatContent>
          <br/>
          <ChatInputContainer>
            <ChatInput
              type="text"
              value={inputText}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}

              placeholder="Type a message..."
            />
            <ChatButton onClick={handleSendMessage}>Send</ChatButton>
          </ChatInputContainer>
        </ChatContainer>
      </div>
         </Grid>
      </Grid>
      
    </>
  );
}

export default Assistant;
