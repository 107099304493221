import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Typography, Button, Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import sessionstorage from 'sessionstorage';
import SearchIcon from '@mui/icons-material/Search';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import "./SectionA.css";


function SectionA() {
  const [width, setWidth] = useState(window.innerWidth);
  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const navigateTo =()=> {
    if(user) {
      sessionstorage.setItem("navBtn", "Pitch Deck Templates")

      navigate('/templates')
    } else {
      navigate('/auth')
    }
   
  }

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);


 
  return (
  
    <section id='home' style={{display:"flex", justifyContent:"center", marginTop:width>700?"6rem":"1rem", textAlign:"center",animation: "fadeIn 1s ease-in-out",}} >
        <Box sx={{
        textAlign: 'center',
        padding: '20px',
        backgroundColor: '#fff', // Set the background color if needed
      }}>
        <Button variant="contained"

        sx={{
        
          backgroundColor: 'linear-gradient(45deg, #007bff, #6610f2)', // Set the button color
          color: 'white', // Set the text color
          '&:hover': {
            backgroundColor: 'linear-gradient(45deg, #007bff, #6610f2)', // Set the hover color
            color:"white",
            boxShadow:'none'
          },
          borderRadius:"25px",
          paddingLeft:"2rem",
          paddingRight:"2rem",
          marginBottom:"2rem",
          textTransform:"none",
          fontWeight:"bold",
          boxShadow:'none'

        }}>
          <ElectricBoltIcon style={{paddingRight:"1rem"}}/> Search with MayaIQ AI Assistant
        </Button>

        {/* Typography for 'Perfect your pitch deck' */}
        <Typography  variant="h3" component="h1" sx={{
          fontWeight: '550', // Set the font weight
          color: '#101828', // Set the text color
          // textShadow: '2px 2px #B0BEC5', // Set the text shadow to match the image
          fontSize:width>700?'55px':"40px",
          // textShadow: '0px 2px #B0BEC5', // Set the text shadow to match the image
          fontFamily:'sans-serif, manrope',
          animation: "fadeIn 1s ease forwards"

        }}>
          Welcome to
        </Typography>

        {/* Typography for 'with AI precision' */}
        <Typography variant="h3" component="h2" sx={{
          fontWeight: '550', // Set the font weight
          color: 'linear-gradient(45deg, #007bff, #6610f2)', // Set the text color to match the highlighted text
          fontSize:width>700?'55px':"40px",
          // textShadow: '0px 0px #B0BEC5', // Set the text shadow to match the image
          fontFamily:'sans-serif, manrope',
          animation: "fadeIn 1s ease forwards"
        }}>
           MayaIQ
        </Typography>

        {/* Typography for the description */}
        <Typography sx={{
          marginTop: '20px',
          fontSize: '18px', // Set the font size
          color: '#455A64', // Set the text color
          // animation: "slideInFromLeft 1s ease forwards"
          animation: "fadeIn 1s ease forwards"
        }}>
         Designed to help buyers and sellers to have fast experience with our AI system
      
         
        </Typography>

        {/* Button for 'Learn more about our $2k pitch competition' */}
        
        <div style={{ display: 'flex', justifyContent:"center", flexDirection: width > 800 ? 'row' : 'column', marginTop: '2rem' }}>
          
          <a href='/buyerauth'>
          <Button
             style={{
              background: 'linear-gradient(45deg, #007bff, #6610f2)',
              color: '#FFFFFF',
              height: '40px',
              borderRadius: '5px',
              width: '100%',
              padding:"1.7rem 2rem",
              fontSize: '14px',
              fontFamily:'sans-serif, manrope',
              
              borderColor: 'linear-gradient(45deg, #007bff, #6610f2)',
              cursor:"pointer",
              textTransform:"none",
              fontWeight: '550',
              animation: "fadeIn 1s ease forwards"

            }}
           
          >
            Buyer
          </Button>
          </a>
          <p style={{margin: "1rem 1rem", color:"#475467", fontWeight:"550", fontSize:"16ox"}}>OR</p>
          <a href='/sellerauth' style={{ }}>
         
          <Button
             style={{
              background: 'white',
              color: 'linear-gradient(45deg, #007bff, #6610f2)',
              height: '40px',
              borderRadius: '5px',
              width: '100%',
              padding:"1.7rem 2rem",
              fontSize: '14px',
              fontFamily:'sans-serif, manrope',
              border: '1px solid blue',

              borderColor: 'blue',
              cursor:"pointer",
              textTransform:"none",
              fontWeight: '550',
              animation: "fadeIn 1s ease forwards"

            }}
           
          >
            Seller
          </Button>
          </a>
        </div>
        <p style={{margin: "1rem 1rem", color:"#475467", fontWeight:"550", fontSize:"16px",animation: "fadeIn 1s ease forwards"}}>Try for free. No credit card required</p>
      </Box>
      {width>700?
      <div style={{ width: '100%',   height: '700px', position: 'absolute', right: 0, left:0, bottom: 0, pointerEvents: 'none' }}>
      
      <img src="/bg-a.png" style={{height:"auto", width:"100%", }}/>
     

    </div>: <div style={{ width: '100%',  height: '400px', position: 'absolute', right: 0, left:0, bottom: 0, pointerEvents: 'none' }}>
      
    <img src="/bg-a.png" style={{height:"auto", width:"100%", }}/>

  </div>}
    </section>
  );
}

export default SectionA;
