import React,{useState, useEffect} from "react";
import GoogleIcon from '@mui/icons-material/Google';
import { authActions } from "../../redux-store";
import { ToastContainer, toast } from "react-toastify";
import { CircularProgress, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import backend from "../../api";
import { useNavigate } from "react-router-dom";
import DangerousIcon from "@mui/icons-material/Dangerous";
import CheckIcon from '@mui/icons-material/Check';
import GoogleMapReact from "google-map-react";
import MyGreatPlace from "../Profile/Mapview/my_great_place";

import axios from 'axios';

import './Auth.css';


const label = { inputProps: { 'aria-label': 'Size switch demo' } };

let role = "seller";


function SellerAuth() {

    const [width, setWidth] = useState(window.innerWidth);
    const [state, setState] = useState("signin");
    const { user } = useSelector((state) => state.auth);
    const [email, setEmail] = useState("");
    const [fullname, setFullName] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState();
    const [location, setLocation] = useState();
    const [description, setDescription] = useState();
    const [latitude, setLatitude] = useState(56);
    const [longitude, setLongitude] = useState(56);

    const [uploadBrochure, setUploadBrochure] = useState([]);
    const [brochureLoading, setBrochureLoading] = useState(false);
  

    const defaultProps = {
      center: {
        lat: latitude,
        lng: longitude,
      },
      zoom: 4,
    };
    const dispatch = useDispatch();
    const navigateTo = useNavigate();

    const extractFileNames = (name) => {
      // Split the name by "/" and take the last part as the file name
      const parts = name?.split('/');
      return (parts[parts?.length - 1]);
  
  };

    const updateDimensions = () => {
      setWidth(window.innerWidth);
    };
    useEffect(() => {
      window.addEventListener('resize', updateDimensions);
      return () => window.removeEventListener('resize', updateDimensions);
    }, []);


    const uploadMedia = async (files, name) => {
      const isValid = Array.from(files).every((file) => file.type === "image/png");
      console.log(files)
      if (!isValid) {
        alert("File format not supported");
        return;
      }
  
      setBrochureLoading(true);
      try {
        const formData = new FormData();
        Array.from(files).forEach((file) => formData.append("files", file));
         console.log(formData)
        const response = await axios.post("https://secret-journey-87277-02643b796295.herokuapp.com/api/upload", formData)
        console.log(response)
  
        if (name === "uploadBrochure") {
          const updatedFiles = response.data.data.map((d) => ({ ...d, key: d.name }));
          setUploadBrochure(prev => [...prev, ...updatedFiles]);
        }
        // toast.success("Files uploaded successfully");
      } catch (error) {
        alert(error.response?.data?.message || error.message || "An error occurred");
      } finally {
        setBrochureLoading(false);
      }
    };
    const handleFileChange = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        uploadMedia(e.target.files, e.target.name);
      }
    };
  
    const handleIconClick = () => {
      document.getElementById('formFile').click();
    };
  
    const handleRemoveFile = (index) => {
      setUploadBrochure(current => current.filter((_, i) => i !== index));
    };

    const loginSubmitHandler = async (e) => {
      e.preventDefault();
      setLoading(true)
      const isLogin = await dispatch(authActions.login(email, password));
      // console.log(dispatch(authActions.login(email, password)))
      
      if (isLogin) {
         
          // handleLogout()
          // await getProjectById();
          setLoading(false)  
       navigateTo("/inbox");
      } 
      if (!isLogin) {
        setLoading(false)  
        alert("Wrong Credentials");
        
      }
    };

    const img = uploadBrochure[0]?.imgurl;
    
    const SignUpHandler = async (e) => {
      e.preventDefault();
      setLoading(true)
      try {
        if(email !="" && email!=null && email !=undefined &&
        password !="" && password!=null && password !=undefined
        ) {
        if(email.includes("@")  && password.length>5) {
        const isSignup = await dispatch(
          authActions.signUp(email, password, fullname, role, img, location, description,longitude, latitude)
        );
        if (isSignup) {
         setLoading(false)
         
          console.log(isSignup)
          // setState(4)
          navigateTo("/inbox");
      }
        if (!isSignup) {
          setLoading(false)
          alert("Wrong credentials");
        }
      
    }
      else {
        setLoading(false)
        alert('Enter correct email & password length>5')
      } 
      } else {
        setLoading(false)
        alert('Enter correct email & password length>5')
      } 
      } catch (e) {
        setLoading(false)
        alert("Network Error");
      }
    };
  
    const ForgotHandler = async (e) => {
      e.preventDefault();
      setLoading(true)
      try {
        const isforgot = await dispatch(authActions.forgotPassword(email));
         if (isforgot) {
          setLoading(false)
          alert('Password sent on email')
         } else {
          setLoading(false)
          alert('Enter valid registered email')
         }
     }  catch (err) {
      setLoading(false)
        alert('Network error')
      }
      }
  

    const boxStyles = {
        // marginTop: "1rem",
        background: "white",
        padding: "40px",
        height: "auto",
        width: width>800?"max-content":"auto",
        borderRadius: "16px",
        boxShadow: "0 4px 8px rgba(16, 24, 40, 0.1)",
      };

  return loading? <div
  style={{
    display: "flex",
    justifyContent: "center",
    height: "100vh",
    alignItems: "center",
  }}
>
  <CircularProgress />
</div>:(
    <section style={{ minHeight: "100vh",  background: "linear-gradient(45deg, #007bff, #6610f2)",
    backgroundSize: 'cover',
    WebkitBackgroundSize: 'cover',
    MozBackgroundSize: 'cover',
    OBackgroundSize: 'cover',
    padding:"1rem",
    display:"flex",
    justifyContent: "center",
    alignItems: "center",
   }}>
     
     {state=="signin"?
      <div>
        <div style={{ display: "flex", justifyContent: "center"  }}>
      <div style={boxStyles}>
      <div style={{ display: "flex",
            justifyContent: "center"}}>
              <a href="/" style={{textDecoration:"none"}}>
        <img src="/logo-orange.png" alt="logo" style={{width:"65px",height: "65px"}}/>
        </a>

        </div>

      <h1
          style={{
            fontWeight: "550",
            marginTop:"2rem",
            fontSize: width > 800 ? "20px" : "17px",
            fontFamily: "Hellix, sans-serif",
            display: "flex",
            justifyContent: "center",
          }}
        >
                      Signin to Seller Portal

        </h1>
        <p style={{color:"gray", fontSize:"14px", textAlign:"center"}}>Sign in to MayaIQ</p>

        <br/>
        <button style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'white',
        fontSize: '14px',
        fontWeight: '600',
        fontFamily: 'Poppins, sans-serif',
        borderRadius: '8px',
        height: '44px',
        width: "100%",
        color: 'rgba(78, 86, 109, 1)',
        border: '1px solid rgba(230, 232, 236, 1)',
        cursor:"pointer"
      }}>
        <div style={{ marginRight: '10px' }}>
           <img style={{height:"24px", width:"24px"}} src="/google.png" alt="google" />
        </div>
        Continue with google
      </button>  
      <div className="text-with-lines">
  <span>OR</span>
</div>
         <h1
          style={{
            fontWeight: "550",
            fontSize: width > 800 ? "16px" : "14px",
            fontFamily: "Poppins, sans-serif",
          }}
        >
         Email
        </h1>
        <input onChange={(e)=>setEmail(e.target.value)} type="email" placeholder="Enter your email" style={{paddingLeft:"1rem", fontSize:"16px",fontFamily: "Poppins, sans-serif", borderRadius:"8px", height:"44px", width:"95%", color:"rgba(36, 36, 54, 1)", border:"1px solid rgba(230, 232, 236, 1)"}}/>
        <br/><br/>
        <h1
          style={{
            fontWeight: "550",
            fontSize: width > 800 ? "16px" : "14px",
            fontFamily: "Poppins, sans-serif",
          }}
        >
         Password
        </h1>
        <input onChange={(e)=>setPassword(e.target.value)} type="password" placeholder="Enter your password" style={{paddingLeft:"1rem", fontSize:"16px",fontFamily: "Poppins, sans-serif", borderRadius:"8px", height:"44px", width:"95%", color:"rgba(36, 36, 54, 1)", border:"1px solid rgba(230, 232, 236, 1)"}}/>
          
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '10px' }}>
      <div style={{ flexGrow: 1 }}>
        <p>
          <input type="checkbox" /><span style={{marginLeft:"0.5rem"}}>Remember me</span>
        </p>
      </div>
      <div>
        <button onClick={()=>setState('forgot')}  style={{color:"linear-gradient(45deg, #007bff, #6610f2)", cursor:"pointer", marginRight:"-0.2rem", borderColor:"transparent", background:"transparent", fontSize:"14px", fontWeight:"600", fontFamily:"Poppins, sans-serif"}}>
            Forgot password
        </button>
      </div>
    </div><br/>
    <button onClick={(e)=>loginSubmitHandler(e)} style={{textAlign:"center", cursor:"pointer", background:"linear-gradient(45deg, #007bff, #6610f2)", fontSize:"14px", fontWeight:"600",fontFamily: "Poppins, sans-serif", borderRadius:"8px", height:"44px", width:"100%", color:"white", border:"1px solid transparent"}}>
        Sign in
    </button>
    
     
      <p style={{textAlign:"center", color:"rgba(78, 86, 109, 1)",fontSize:"14px",  fontFamily: 'Poppins, sans-serif',marginTop:"2rem"}}>Don't have an account? 
      <button onClick={()=>setState("signup")} style={{color:"linear-gradient(45deg, #007bff, #6610f2)", cursor:"pointer", borderColor:"transparent", background:"transparent", fontSize:"14px", fontWeight:"600", fontFamily:"Poppins, sans-serif"}}>
            Sign up
        </button>
      </p>
   
      </div>
    </div>
      </div>:state=="signup"? 
      <div>
      <div style={{ display: "flex", justifyContent: "center",  }}>
    <div style={boxStyles}>
        <div style={{ display: "flex",
            justifyContent: "center"}}>
   <a href="/" style={{textDecoration:"none"}}>
        <img src="/logo-orange.png" alt="logo" style={{width:"65px",height: "65px"}}/>
        </a>
        </div>
      <h1
          style={{
            fontWeight: "550",
            marginTop:"2rem",
            fontSize: width > 800 ? "20px" : "18px",
            fontFamily: "Hellix, sans-serif",
            display: "flex",
            justifyContent: "center",
          }}
        >
       Signup to Seller Portal

        </h1>
        <p style={{color:"gray", fontSize:"14px", textAlign:"center"}}>Sign up to MayaIQ</p>
        <br/>
        <button style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'white',
        fontSize: '14px',
        fontWeight: '600',
        fontFamily: 'Poppins, sans-serif',
        borderRadius: '8px',
        height: '44px',
        width: "100%",
        color: 'rgba(78, 86, 109, 1)',
        border: '1px solid rgba(230, 232, 236, 1)',
        cursor:"pointer"
      }}>
        <div style={{ marginRight: '10px' }}>
           <img style={{height:"24px", width:"24px"}} src="/google.png" alt="google" />
        </div>
        Continue with google
      </button>  
      <div className="text-with-lines">
  <span>OR</span>
</div>
       <h1
        style={{
          fontWeight: "550",
          fontSize: width > 800 ? "16px" : "14px",
          fontFamily: "Poppins, sans-serif",
        }}
      >
       Email
      </h1>
      <input onChange={(e)=>setEmail(e.target.value)} type="email" placeholder="Enter your email" style={{paddingLeft:"1rem", fontSize:"16px",fontFamily: "Poppins, sans-serif", borderRadius:"8px", height:"44px", width:"95%", color:"rgba(36, 36, 54, 1)", border:"1px solid rgba(230, 232, 236, 1)"}}/>
      <br/><br/>
      <h1
        style={{
          fontWeight: "550",
          fontSize: width > 800 ? "16px" : "14px",
          fontFamily: "Poppins, sans-serif",
        }}
      >
       Password
      </h1>
      <input onChange={(e)=>setPassword(e.target.value)} type="password" placeholder="Enter your password" style={{paddingLeft:"1rem", fontSize:"16px",fontFamily: "Poppins, sans-serif", borderRadius:"8px", height:"44px", width:"95%", color:"rgba(36, 36, 54, 1)", border:"1px solid rgba(230, 232, 236, 1)"}}/>
        
      <br/>  
      <br/>

      <h1
        style={{
          fontWeight: "550",
          fontSize: width > 800 ? "16px" : "14px",
          fontFamily: "Poppins, sans-serif",
        }}
      >
       Full Name
      </h1>
      <input onChange={(e)=>setFullName(e.target.value)} type="fullname" placeholder="Enter your name" style={{paddingLeft:"1rem", fontSize:"16px",fontFamily: "Poppins, sans-serif", borderRadius:"8px", height:"44px", width:"95%", color:"rgba(36, 36, 54, 1)", border:"1px solid rgba(230, 232, 236, 1)"}}/>
        
      <br/>  
      <br/>

      <h1
        style={{
          fontWeight: "550",
          fontSize: width > 800 ? "16px" : "14px",
          fontFamily: "Poppins, sans-serif",
        }}
      >
       Location Address
      </h1>
      <input onChange={(e)=>setLocation(e.target.value)} type="location" placeholder="Enter your address" style={{paddingLeft:"1rem", fontSize:"16px",fontFamily: "Poppins, sans-serif", borderRadius:"8px", height:"44px", width:"95%", color:"rgba(36, 36, 54, 1)", border:"1px solid rgba(230, 232, 236, 1)"}}/>
      <br/><br/>

      <h1
        style={{
          fontWeight: "550",
          fontSize: width > 800 ? "16px" : "14px",
          fontFamily: "Poppins, sans-serif",
        }}
      >
       Description
      </h1>
      <input onChange={(e)=>setDescription(e.target.value)} type="description" placeholder="Describe yourself and your services" style={{paddingLeft:"1rem", fontSize:"16px",fontFamily: "Poppins, sans-serif", borderRadius:"8px", height:"44px", width:"95%", color:"rgba(36, 36, 54, 1)", border:"1px solid rgba(230, 232, 236, 1)"}}/>
      <br/><br/>
     
      <div style={{display:"flex", alignItems:"center",padding:"0.3rem 0.5rem"}}>
      {!brochureLoading && uploadBrochure.length==0?
      <div style={{display:"flex", alignItems:"center"}}>
          <span style={{fontWeight:"600", fontFamily:"sans-serif, manrope", fontSize:"14px"}}>Upload Image</span>
          {/* <IconButton onClick={()=>handleEditClick(value.name, index)}>
            <EditIcon style={{fontSize:"20px"}}/>
          </IconButton> */}
        </div>:null}
      {brochureLoading && <CircularProgress size={10} style={{color:"blue"}}/>}
      {uploadBrochure?.length > 0 && (
                <Box sx={{ backgroundColor: "#e9ecef", fontSize: "12px", width: "auto" }}>
                  {uploadBrochure?.map((file, i) => (
                    <React.Fragment key={i}>
                      <span style={{color:"black"}}>{extractFileNames(file?.imgurl).slice(0, 5)}...</span>
                      <DangerousIcon style={{fontSize:"20px"}} onClick={() => handleRemoveFile(i)} />
                    </React.Fragment>
                  ))}
                </Box>
              )}
  
      <input
                onChange={handleFileChange}
                accept=".png"
                name="uploadBrochure"
                className="form-control"
                type="file"
                id="formFile"
                multiple
                style={{ display: 'none' }}
              />
      {
        !brochureLoading && uploadBrochure.length==0?   
      <a
      style={{ marginLeft:"auto",cursor: "pointer", textDecoration:"none"}}
      onClick={handleIconClick}

    >
      <img src="/icons/add-white.png" alt="uploadIcons" style={{width:"15px", height:"14px", cursor: "pointer"}}
      />
    </a>
      :
      !brochureLoading?<span>Uploaded Successfully</span>:null
      }
   
    </div>

    <h4>Choose location</h4>
        <input type="number" value={latitude} onChange={(e)=>setLatitude(e.target.value)} placeholder="Enter latitude"/>
        <input type="number" value={longitude} onChange={(e)=>setLongitude(e.target.value)} placeholder="Enter longitude"/>
       
        <div style={{ height: '200px', paddingTop:"0.5rem", width: '100%' }}>
        <GoogleMapReact
              bootstrapURLKeys={{ key: "enterKeyHere" }}
              defaultCenter={{
                lat: latitude,
                lng: longitude
              }}
              defaultZoom={defaultProps.zoom}
            >
              <MyGreatPlace
                lat={latitude}
                lng={longitude}
                text={"A"} // You can replace "A" with something related to your listing like an icon or a label
              />
            </GoogleMapReact>
            </div>
            <br/>
            <br/>
      <p style={{fontSize:"11px", color:"gray"}}>By creating an account, you agree to the <a style={{textDecoration:"underline", color:"blue"}}>Terms of Service</a> and <a style={{textDecoration:"underline", color:"blue"}}>Privacy Policy.</a></p>
  <button  onClick={(e) => SignUpHandler(e)} style={{textAlign:"center", cursor:"pointer", background:"linear-gradient(45deg, #007bff, #6610f2)", fontSize:"14px", fontWeight:"600",fontFamily: "Poppins, sans-serif", borderRadius:"8px", height:"44px", width:"100%", color:"white", border:"1px solid transparent"}}>
      Sign up
  </button>
    
    <p style={{textAlign:"center", color:"rgba(78, 86, 109, 1)",fontSize:"14px",  fontFamily: 'Poppins, sans-serif',marginTop:"2rem"}}>Have an account? 
    <button onClick={()=>setState("signin")} style={{color:"linear-gradient(45deg, #007bff, #6610f2)", cursor:"pointer", borderColor:"transparent", background:"transparent", fontSize:"14px", fontWeight:"600", fontFamily:"Poppins, sans-serif"}}>
          Sign in
      </button>
    </p>
 
    </div>
    

  </div>
    </div>:
    
    <div>

    {/* <p
      style={{
        fontWeight: "400",
        fontSize: width > 800 ? "16px" : "14px",
        fontFamily: "Poppins, sans-serif",
        color: "rgba(78, 86, 109, 1)",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      Please enter your details.
    </p> */}

    <div style={{ display: "flex", justifyContent: "center",  }}>
  <div style={boxStyles}>
  <h1
      style={{
        fontWeight: "600",
        fontSize: width > 800 ? "22px" : "22px",
        fontFamily: "Hellix, sans-serif",
        display: "flex",
        justifyContent: "center",
        
      }}
    >
     Forgot your password
    </h1>
     <h1
      style={{
        fontWeight: "550",
        fontSize: width > 800 ? "16px" : "14px",
        fontFamily: "Poppins, sans-serif",
      }}
    >
     Email
    </h1>
    <input onChange={(e)=>setEmail(e.target.value)} type="email" placeholder="Enter your email" style={{paddingLeft:"1rem", fontSize:"16px",fontFamily: "Poppins, sans-serif", borderRadius:"8px", height:"44px", width:"95%", color:"rgba(36, 36, 54, 1)", border:"1px solid rgba(230, 232, 236, 1)"}}/>
 <br/>
<br/>
<button onClick={()=>ForgotHandler()} style={{textAlign:"center", cursor:"pointer", background:"linear-gradient(45deg, #007bff, #6610f2)", fontSize:"14px", fontWeight:"600",fontFamily: "Poppins, sans-serif", borderRadius:"8px", height:"44px", width:"100%", color:"white", border:"1px solid transparent"}}>
    Send Message
</button>
<br/>
  
  <p style={{textAlign:"center", color:"rgba(78, 86, 109, 1)",fontSize:"14px",  fontFamily: 'Poppins, sans-serif',marginTop:"4rem"}}>Already know password? 
  <button onClick={()=>setState("signin")} style={{color:"linear-gradient(45deg, #007bff, #6610f2)", cursor:"pointer", borderColor:"transparent", background:"transparent", fontSize:"14px", fontWeight:"600", fontFamily:"Poppins, sans-serif"}}>
        Sign in
    </button>
  </p>

  </div>
  

</div>
  </div>
    }
    </section>
  );
}

export default SellerAuth;
