import React,{useState, useEffect} from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
    
    const [width, setWidth] = useState(window.innerWidth);

    const updateDimensions = () => {
      setWidth(window.innerWidth);
    };
    useEffect(() => {
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
    }, []);

  return (
    <Box component="footer" sx={{ backgroundColor: '#FBFBFD', padding: '40px 0', marginTop:"5rem", display:"flex", justifyContent:"center" }}>
      <Container maxWidth="lg">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item container xs={12} sm={2.5} xl={2.3} justifyContent="center">
            <img src="/logo-orange.png" alt='logo' style={{width:"65px",height: "65px"}}/>
            <Typography style={{fontSize:"10px"}} color="textSecondary" >
              © Copyright MayaIQ All Rights Reserved.
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={7} xl={7.5} justifyContent="center" spacing={3}>
            <Grid item>
              <Link href="#" color="inherit" style={{textDecoration:"none", fontWeight:"600"}}>
                Contact Us
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" color="inherit" style={{textDecoration:"none", fontWeight:"600"}}>
                Privacy Policy
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" color="inherit" style={{textDecoration:"none", fontWeight:"600"}}>
                Terms of Service
              </Link>
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={2.5} xl={2.2} justifyContent={width>700?"flex-end":"center"} spacing={2}>
          <Grid item>
              <Link href="/" target="_blank">
                <img src='/linkedin.png' style={{ width:"25px", height:"25px", padding:"0.2", borderRadius:"5px"}}/>

              </Link>
            </Grid>
            <Grid item>
              <Link href="/" target="_blank">
              <img src='/insta.png' style={{ width:"25px",  height:"25px", padding:"0.2", borderRadius:"5px"}}/>

              </Link>
            </Grid>
            </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
