import React from "react";
import "./account.css";
import { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
// import formLeft from "../../assets/formLeft.png";
import { TextField, useMediaQuery } from "@mui/material";
import { toast } from "react-toastify";
import { Grid, Paper, Typography, Button } from '@mui/material';
import FileUpload from "./FileUpload";
import GoogleMapReact from "google-map-react";
import MyGreatPlace from "./Mapview/my_great_place";

import backend from "../../api";



export default function Account() {

  const [preferences, showPreferences] = useState(true);
  const [profileDetails, showprofileDetails] = useState(true);
  const [plans, showPlans] = useState(false);
  const [userData, setUserData] = useState();
  const [confirmEmail, setConfirmEmail] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [latitude, setLatitude] = useState(56);
  const [longitude, setLongitude] = useState(56);
  const { user } = useSelector((state) => state.auth);
   

  const defaultProps = {
    center: {
      lat: latitude,
      lng: longitude,
    },
    zoom: 4,
  };
  
  const email = user?.email;

  const matches = useMediaQuery("(min-width:1024px)");
  console.log(userData);



  const fetchUserID = async () => {
    const res = await backend.get(`/user?email=${email}`);
    console.log(res);
    setUserData(res.data);
  };
  useEffect(() => {
    fetchUserID();
  }, []);
  const userCanEdit = (e) => {
    const inputValue = e.target.value;
    setUserData({ ...userData, [e.target.name]: inputValue });
  };



  const ProfileDetails = () => {
    showprofileDetails(true);
    showPlans(false);
  };

  const PlansAndBilling = () => {
    showprofileDetails(false);
    showPlans(true);
    
  };

  const updateUserMap = async (e) => {
    e.preventDefault();
    const body = {
      latitude: latitude,
      longitude: longitude
    };
    if (!latitude || !longitude) {
      alert("Lng and Lng is required");
      return;
    }
    const resp = await backend.put(
      `/user/accountuserInfo/${userData?._id}`,
      body
    );
    alert("User Updated");
    window.location.reload();
  };

  const updateUserNameDetail = async (e) => {
    e.preventDefault();
    const body = {
      firstName: userData?.firstName,
      lastName: userData?.lastName,
    };
    if (!userData?.firstName || !userData?.lastName) {
      alert("FirstName and Last name is required");
      return;
    }
    const resp = await backend.put(
      `/user/accountuserInfo/${userData?._id}`,
      body
    );
    alert("User Updated");
    fetchUserID();
  };

  const updateUserEmail = async (e) => {
    try {
      debugger;
      e.preventDefault();
      const body = {
        email: userData?.email,
      };
      if (userData?.email !== confirmEmail) {
        console.log("error");
        alert("email is not correct");
        return;
      }
      debugger;
      await backend.put(
        `/user/accountuserInfo/${userData?._id}`,
        body
      );
      alert("Email Updated");
      debugger;
      fetchUserID();
    } catch (error) {
      alert(error?.response?.data?.message || error?.message || error);
    }
  };
  const updateUserPassword = async (e) => {
    e.preventDefault();
    const body = {
      password: userData?.password,
    };
    if (userData?.password !== confirmPassword) {
      alert("password is not match");
      return;
    }
    const resp = await backend.put(
      `/user/accountuserInfo/${userData?._id}`,
      body
    );
    alert("Password Updated");
    fetchUserID();
  };



  const containerStyle = {
    margin:"2rem",
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: '20px',
    backgroundColor: 'rgb(237, 231, 246)',
    padding: '1rem',
    color: 'black',
    borderRadius: '8px',
    maxWidth: '600px',
  };
  
  const planStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
  };
  
  const planDetailsStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gridGap: '20px',
  };
  
  const inputStyle = {
    // width: '100%',
    padding: '10px',
    border: 'none',
    backgroundColor: '#f2f2f2',
    borderRadius: '4px',
    marginBottom: '10px',
  };
  
  const safeAndSecureTextStyle = {
    fontSize: '12px',
    fontStyle: 'italic',
    marginTop: '10px',
  };
  
  const payNowButtonStyle = {
    backgroundColor: 'linear-gradient(45deg, #007bff, #6610f2)',
    color: 'white',
    fontSize: '16px',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
  };
  
  const useCouponButtonStyle = {
    backgroundColor: '#f2f2f2',
    color: 'linear-gradient(45deg, #007bff, #6610f2)',
    fontSize: '14px',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.2s, color 0.2s',
    marginRight: '10px',
  };
  
  const applyCodeLinkStyle = {
    color: 'dodgerblue',
    textDecoration: 'none',
    marginLeft: '5px',
  };
  
  const stripeTextStyle = {
    marginTop: '20px',
    textAlign: 'center',
  };
  


  const [userID, setUserID] = useState("");
  const [workflows, setWorkflows] = useState([]);
  const [apiKey, setAPIKey] = useState('');
  const [botLanguage, setBotLanguage] = useState('');
  console.log(botLanguage)

    useEffect(() => {
        const fetchData = async () => {
        //   setLoading(true);
          try {
            const fetchUserID = async () => {
              const res = await backend.get(`/user?email=${email}`);
              setUserID(res?.data?._id);
              setUserData(res?.data);
              setLatitude(res?.data?.lat);
              setLongitude(res?.data?.lng);
            };
            await fetchUserID();
    
          } catch (err) {
            console.error(err);
          } finally {
            // setLoading(false);
          }
        };
    
        fetchData();
      }, [userID]);

   


  
  return (
    <div style={{marginTop:"1rem"}}>
        <div style={{padding:"1rem"}}>
          <p style={{fontSize:"30px", fontWeight:"600"}}>Account Information</p>
          <div style={{marginBottom:"1rem"}}>
           
            <button
            style={{marginRight:"1.5rem", padding:"0.5rem", border:"1px solid transparent",borderRadius:"25px", cursor:"pointer",background: profileDetails?"linear-gradient(45deg, #007bff, #6610f2)":"transparent", color: profileDetails?"white":"black", fontWeight:"600"}}
              onClick={() => ProfileDetails()}
            >
             Profile Details
            </button>
            <button
            style={{marginRight:"1.5rem", padding:"0.5rem", border:"1px solid transparent",borderRadius:"25px", cursor:"pointer",background: plans?"linear-gradient(45deg, #007bff, #6610f2)":"transparent", color: plans?"white":"black", fontWeight:"600"}}
              onClick={() => PlansAndBilling()}
            >
                Plans and Billing
            </button>
            {/* <button
            style={{marginRight:"1.5rem", padding:"0.5rem", border:"1px solid transparent",borderRadius:"25px", cursor:"pointer",background: apiDashboard?"linear-gradient(45deg, #007bff, #6610f2)":"transparent", color:apiDashboard ?"white":"black", fontWeight:"600"}}
              onClick={() => APIDashboard()}
            >
                API Dashboard
            </button> */}
          </div>


          {profileDetails && (

<Grid container spacing={3} style={{margin:"1rem", width:"auto"}}>
<Grid item xs={12}>
  <Typography variant="h3" style={{fontSize:"25px", fontWeight:"600", marginBottom:"1rem"}}>Personal Information</Typography>
</Grid>
<Grid item xs={12} style={{ padding: '1rem'}}>
  {/* <Paper elevation={1} style={{ padding: '1rem' }}> */}
    <Grid container spacing={2} style={{background:"rgb(237, 231, 246)", border:"1px solid transparent", width:"90%", padding:"1rem", borderRadius:"15px"}}>
      <Grid item xs={12} sm={6} md={5}>
        <TextField
          fullWidth
          label="First Name"
          name="username"
          value={userData?.username}
          onChange={userCanEdit}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={5}>
        <TextField
          fullWidth
          label="Last Name"
          name="lastName"
          value={userData?.lastName}
          onChange={userCanEdit}
        />
      </Grid>
      <Grid item md={10} style={{justifyContent:"flex-end", display:"flex"}}>
      <Button
          style={{background:"linear-gradient(45deg, #007bff, #6610f2)", textTransform:"none", color:"white", borderRadius:"20px", fontSize:"13px"}}
          
          onClick={updateUserNameDetail}
        >
          Update Name
        </Button>
      </Grid>
      <Grid item xs={12} sm={6} md={5}>
        <TextField
          fullWidth
          label=""
          name="email"
          value={userData?.email}
          onChange={userCanEdit}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={5}>
        <TextField
          fullWidth
          label="Confirm your email"
          name="email"
          value={confirmEmail}
          onChange={(e) => setConfirmEmail(e.target.value)}
        />
      </Grid>

      <Grid item md={10} style={{justifyContent:"flex-end", display:"flex"}}>
      <Button
          style={{background:"linear-gradient(45deg, #007bff, #6610f2)", textTransform:"none", color:"white", borderRadius:"20px", fontSize:"13px",marginLeft: '1rem'}}
          
          onClick={updateUserEmail}
        >
          Update Email
        </Button>
      </Grid>


      <Grid item xs={12} sm={6} md={5}>
        <TextField
          fullWidth
          label=""
          type="password"
          name="password"
          value={userData?.password}
          onChange={userCanEdit}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={5}>
        <TextField
          fullWidth
          label="Confirm Password"
          type="password"
          name="confirmpassword"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e?.target.value)}
        />
      </Grid>

      <Grid item md={10} style={{justifyContent:"flex-end", display:"flex"}}>
      <Button
          style={{background:"linear-gradient(45deg, #007bff, #6610f2)",textTransform:"none",  color:"white", borderRadius:"20px", fontSize:"13px", marginLeft: '1rem' }}
          onClick={updateUserPassword}
        >
          Update Password
        </Button>
      </Grid>

      
    </Grid>
  {/* </Paper> */}
</Grid>
</Grid>
            
          )}

          {plans && (
            
<div style={containerStyle} >
<p style={planStyle}>Your Current Plan: Free</p>
<div>
  <form>
    <div style={planDetailsStyle}>
      <div>
        <div>
          <div>
            Upgrade to Pro Plan
            <br />
            147 / Annual
          </div>
          <div>
            <input type="checkbox" id="annual" />
            <label htmlFor="annual">&nbsp;&nbsp;Pay Annually</label>
            <br />
            <input type="checkbox" id="monthly" />
            <label htmlFor="monthly">&nbsp;&nbsp;Pay Monthly</label>
            <br />
          </div>
        </div>
      </div>

      <div>
        <ul>
          <li>Get Access to all pro features</li>
          <li>Unlimited Prompts</li>
          <li>Unlimited Projects</li>
          <li>Save unlimited copy</li>
          <li>Access all your copy in one place</li>
          <li>Get 24/7 support by email</li>
        </ul>
      </div>
    </div>

    <div>
      {/* <div>
        <input
          type="text"
          value="Name on the card"
          style={inputStyle}
        />
      </div> */}

      <div style={safeAndSecureTextStyle}>
        Safe and secure.
        <br />
        Cancel anytime.
      </div>
    </div>

    <div>
      {/* <div>
        <input
          type="text"
          value="Card Number"
          style={inputStyle}
        />
      </div> */}

      <div>
        <a style={{textDecoration:"none", background:"blue", color:'white', marginBottom:"1rem"}} href="https://buy.stripe.com/test_6oE7w71Mjfnuc2AdQQ">
          Pay Now ✈️
        </a>
      </div>
    </div>

    <div>
      <div>
        <button type="submit" style={useCouponButtonStyle}>
          Use coupon
        </button>
      </div>

      <a href="#" style={{ ...applyCodeLinkStyle, color: 'dodgerblue' }}>
        <u>Apply code</u>
      </a>

      <div style={stripeTextStyle}>
        Powered by Stripe
      </div>
    </div>
  </form>
</div>
</div>
                     )}

        </div>
        <FileUpload/>
        <br/>
        <h2>Choose location</h2>
        <input type="number" value={latitude} onChange={(e)=>setLatitude(e.target.value)} placeholder="Enter latitude"/>
        <input type="number" value={longitude} onChange={(e)=>setLongitude(e.target.value)} placeholder="Enter longitude"/>
        <button onClick={(e)=>updateUserMap(e)}>Save</button><br/>
        <div style={{ height: '500px', width: '100%', paddingTop:"0.5rem" }}>
        <GoogleMapReact
              bootstrapURLKeys={{ key: "" }}
              defaultCenter={{
                lat: latitude,
                lng: longitude
              }}
              defaultZoom={defaultProps.zoom}
            >
              <MyGreatPlace
                lat={latitude}
                lng={longitude}
                text={"A"} // You can replace "A" with something related to your listing like an icon or a label
              />
            </GoogleMapReact>
            </div>
            <br/>
      </div>
  );
}





