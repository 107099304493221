import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import SectionA from './Components/SectionA';

import FAQs from './Components/Faqs';
import { useSelector } from "react-redux";
import {useNavigate} from "react-router-dom"
import sessionstorage from "sessionstorage";

function HomeNew() {
  const [width, setWidth] = useState(window.innerWidth);
  const { user } = useSelector((state) => state.auth);

  const navigateTo = useNavigate();

  useEffect(() => {
    if(user) {
      sessionstorage.setItem("navBtn", "Pitch Deck Dashboard")

      navigateTo('/dashboard')
    }
  },[user]);
  
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <div>
    <SectionA />
   
    <FAQs/>
    </div>
  );
}

export default HomeNew;
