import React,{useState, useEffect} from "react";
import { Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import "./ChatHistory.css";
import backend from "../../../../api";
import { useNavigate } from "react-router-dom";

import { setChatHistoryId } from "../../../../redux-store/auth/actions";
import ChatIcon from '@mui/icons-material/Chat';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DeleteIcon from '@mui/icons-material/Delete';
import { format } from 'date-fns';
import Button from "@mui/material/Button";

const styles = {
    container: {
      width: 'auto',
      cursor: 'pointer',
      border: '1px solid rgb(9 70 122)',
      borderRadius: '20px',
      margin: '1rem',
      paddingLeft: '1rem',
      backgroundColor: 'white',
      display: 'flex',
      maxHeight: "70px",
      flexDirection: 'column',
      // alignItems: 'center',
      justifyContent: 'space-between',
    },
    icon: {
      height: '20px',
      width: '20px',
    },
    quote: {
      fontStyle: 'italic',
    },
    copyIcon: {
      marginTop: 'auto',
    },
  };

function ChatHistory() {
    const [data, setData] = useState([]);
    const { user } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    
    const _id = user._id;
    const projectId =  localStorage.getItem("projectID")
    const navigateTo = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
      const getChats = async () => {
        
        const UserId = user._id;
        setLoading(true)
        try {
          const response = await backend.get("/chats");
          // Filter the response data to include only the required objects
          const filteredChats = response?.data?.data?.filter(
            (item) => item.UserId === UserId ||item.SellerId === UserId  && item.chat.length > 0
          );
          // setLoading(false);
          // console.log(filteredChats);
          setData(filteredChats);
          console.log(response);
          setLoading(false)
        } catch (error) {
          console.error(error);
          // toast.error("Error saving story");
        }
      };
      getChats();
    }, [projectId]);


    const OpenChatHistory = (id) => {
        dispatch(setChatHistoryId(id))
        navigateTo(`/inbox/${id}`);
      };

      const ProjectID = localStorage.getItem('projectID');

      const saveChat = async () => {
        const userId = user._id
        setLoading(true)
          try {
          const body = {
            UserId: userId,
            projectId:"test"
          };
          
          const response = await backend.post("/chats", body);
              // setLoading(false);
            console.log(response)
            const RouteID = response?.data?.data?._id
            // localStorage.setItem("chatId", RouteID)
            if(RouteID) {
              await dispatch(setChatHistoryId(RouteID))
              // await UpdateProjectById(RouteID)
              navigateTo(`/inbox/${RouteID}`)
            }
      
            setLoading(false)

        } catch (error) {
          console.error(error);
          setLoading(false)

          // toast.error("Error saving story");
        }
      
      };

      const DeleteChat = async (id) => {
        
        const UserId = user._id;
        setLoading(true)
        try {
          const response = await backend.delete(`/chats/${id}`);
          // Filter the response data to include only the required objects
          const filteredChats = data?.filter(
            (item) => item.UserId === UserId && item.chat.length > 0 && item._id !== id
          );
          // setLoading(false);
          // console.log(filteredChats);
          setData(filteredChats);
          console.log(response);
          setLoading(false)
        } catch (error) {
          console.error(error);
          // toast.error("Error saving story");
        }
      };

      console.log(data)
  return (
    <div style={{ padding: "1rem", overflow:"auto"}}>
         <Button 
    onClick={() => saveChat()} 
    style={{
        width: "332px", 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: "center",
        textTransform: "none", 
        paddingLeft: "1rem", 
        borderColor: "transparent", 
        cursor: "pointer", 
        borderRadius: "8px", 
        height: "51px", 
        background: "linear-gradient(45deg, #007bff, #6610f2)", 
        color: "white", 
        fontSize: "20px",
        margin: "0 auto", // Added margin auto to center the button horizontally
        marginTop:"1.18rem"
    }}
>
    Chat History
</Button>

     

    <Grid container columns={12} style={{  padding: "1rem",   overflow:"auto", display:"flex", justifyContent:"center"}}>
   
    {
    data?.length > 0 ? (
    data?.map(value => (
      <Grid item xs={12} sm={12} md={12} style={{height:"70px", maxWidth:"340px"}}>
      <div style={{
        display: "flex",
        flexDirection: "row",
        // justifyContent: "space-between", // changed to space-between to align items as in the image
        alignItems: "center",
        cursor:'pointer',
        
      }}>
        <KeyboardArrowDownIcon style={{ color: "black", marginRight: "0.5rem" }} onClick={() => OpenChatHistory(value?._id)} />
        <p style={{ fontWeight: "600", flexGrow: 1 }} onClick={() => OpenChatHistory(value?._id)}>
          {user.role=="buyer"?value?.sellerName:value?.buyerName}
        </p>
        <DeleteIcon className="delete-icon" onClick={() => DeleteChat(value?._id)} />
      </div>
      <span onClick={() => OpenChatHistory(value?._id)} style={{ color: "gray", fontSize: "0.875rem", marginLeft:'2rem' }}>{format(new Date(value?.createdAt), 'dd MMM')}</span> {/* Formatted date */}

    </Grid>
   
      ))):null}
    </Grid>
    </div>
  );
}

export default ChatHistory;
